* {
    box-sizing: border-box;
}

html, body, #root {
    width: 100%;
    height: 100%;
    margin: 0;
}

.App {
    width: 100%;
    display: table;
    font-size: 15px;
    line-height: 1.3;
    font-family: sans-serif, 'Trebuchet MS', Verdana;
    background-color: transparent;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    backface-visibility: hidden;
}

.App.full {
    background-color: rgb(161, 161, 161);

    .content-wrapper {
        padding-top: 75px;
    }
}

.content-wrapper {
    height: auto;
    max-width: 1170px;
    margin: auto;
    background: white;

    & > * {
        width: 100%;
        display: table;
    }
}

.LanguagePicker {
    position: fixed;
    top: 0;
    right: 0;
    text-align: right;
    z-index: 20;

    div {
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
            transform: scale(1.1);
        }
    }

    img {
        width: 30px;
        height: 20px;
    }
}

h1 {
    font-size: 1.7em;
}

img {
    width: 100%;
    height: auto;
    transition: all .2s ease-in-out;
}

a {
    color: black;

    &:hover {
        color: dimgray;
    }
}

iframe {
    background: white;
    border: none;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px black;
}

.error {
    color: #bb1212;
}

.nav-error {
    width: 100%;
    float: left;
    padding: 5px;
    text-align: right;
    color: #bb1212;
}

button,
input[type=button],
input[type=submit] {
    border-radius: 3px;
    min-width: 100px;
    height: 33px;
    padding: 5px 5px 5.5px;
    font-size: 13px;
    font-weight: bold;
    border: none;
    -webkit-appearance: none;
    background-color: buttonface;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:focus {
        outline: none;
    }
}

input[type=text],
input[type=tel],
input[type=email],
input[type=password],
textarea {
    border-radius: 0;
    padding: 5px;
    font-size: 18px;
    font-family: Calibri, Arial, sans-serif;
    border: none;
    background-color: #eee;
    border-radius: 0;
}

input[type=checkbox] {
    vertical-align: middle;
    margin: 0 7px 1px;
}

.nav-buttons {
    width: 100%;
    float: left;
    min-height: 30px;
    padding: 5px 0;
    background-color: #2e2e2e;
    background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,.05));

    a {
        width: 100px;
        display: block;
        text-align: center;
        padding: 5px;
        font-weight: bold;
        color: white;
        text-decoration: none;

        span {
            font-size: 16px;
        }
    }

    .next {
        color: white;
        float: right;
        background-color: green;
        background-image: linear-gradient(rgba(0, 160, 0, 0.1), rgba(0, 200, 0, 0.4));
        margin-right: 5px;

        &:hover {
            background-color: darkgreen;
        }

    }

    .back {
        color: white;
        margin-left: 5px;
        background-color: gray;
        background-image: linear-gradient(rgba(156, 156, 156, 0.1), rgba(193, 197, 193, 0.4));

        &:hover {
            background-color: #696969;
        }

        span {
            margin-left: 10px;
        }
    }
}

.fade-in {
    visibility: visible;
    opacity: 1;
    transition: opacity .5s linear 1s;
}

.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity .5s linear 1s;
    cursor: default;
    pointer-events: none;
}

.Summary {
    width: 100%;
    float: left;
    padding: 20px;
    text-align: center;
}

footer {
    
    margin: auto;
    height: auto;
    max-width: 1170px;
    div{
        padding:20px;
        p{
            margin-top:-12px;
        }
    }
}

@media (max-width: 1024px) {
    iframe {
        border-radius: 0;
    }
}

@media (max-width: 820px) {
    .App {
        font-size: 16px;
    }

    h3 {
        font-size: 1.1em;
    }
}

@media (max-width: 550px) {
    .App.full .content-wrapper {
        padding-top: 55px;
    }
}
