@import '../../styles/mixins';

.DesignList {
    padding: 20px;

    .DesignListItem {
        width: 25%;
        float: left;
        padding: 10px;

        h3 {
            text-align: center;
            min-height: 70px;
        }

        @include img-hover;
    }
}

@media (max-width: 885px) {
    .DesignList .DesignListItem {
        width: 33.33%;
    }
}

@media (max-width: 550px) {
    .DesignList .DesignListItem {
        width: 50%;
    }
}

@media (max-width: 350px) {
    .DesignList .DesignListItem {
        width: 100%;
    }
}
