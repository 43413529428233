@import '../../styles/mixins';

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 15000;
    top: 0;
    left: 0;
    overflow-y: hidden;
    color: floralwhite;
    font-size: 20px;
    background-color: rgba(161, 161, 161, 0.95);

    .overlay-content {
        margin: auto;
        text-align: center;
        padding: 20px 0;
        margin-top: 150px;
        background-color: rgba(0,0,0,0.6);

        @include red;
        @include green;

        input[type=button] {
            margin: 0 10px 20px 10px;
        }

        .reload {
            color: black;
        }
    }
}