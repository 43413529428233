@media print {
    @page {
        margin: 0;
        size: A4;
    }

    body {
        padding: .5cm;
    }

    html, body, #root, .App {
        background: white;
        color: black;
    }

    nav {
        display: none;
    }

    .content-wrapper {
        padding-top: 0;
    }

    a {
        color: black;
    }

    .Receipt {
        .Voucher,
        .no-break {
            page-break-inside: avoid;
        }

        .break-after {
            page-break-after: always;
        }
    }

    input[type=button] {
        display: none;
    }

    .Summary:last-of-type {
        display: none;
    }
}
