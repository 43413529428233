.Faq, 
.Contact {
    padding: 10px 20px 20px;
}

.Faq {
}

.Contact {
    .address {
        width: 33.33%;
        float: left;
    }

    .email-link {
        text-transform: none;
        font-weight: normal;
        font-size: inherit;
    }
}

@media (max-width: 680px) {
    .Contact .address {
        width: 50%;
        padding-bottom: 20px;
    }
}


@media (max-width: 515px) {
    .Contact .address {
        width: 100%;
    }
}