.Summary {
    background: #eee;
    padding: 0;
    text-align: left;

    h3, .summary-info {
        float: left;
        height: 100%;
    }

    h3 {
        width: 20%;
        padding-left: 20px;
        font-size: 1.2rem;
    }

    .summary-info {
        width: 80%;
        padding: 23px;
        background: white;
        color: black;

        div {
            width: 50%;
            float: left;
        }

        .total-price {
            text-align: right;
            font-weight: bold;
        }
    }
}

@media (max-width: 650px) {
    .Summary {
        h3 {
            width: 30%;
        }

        .summary-info {
            width: 70%;
            padding: 20px;
        }
    }
}

@media (max-width: 440px) {
    .Summary {
        h3 {
            text-align: center;
        }

        h3,
        .summary-info {
            width: 100%;
        }
    }
}