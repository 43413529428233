@import '../../../styles/mixins';

.ProductList {
    padding: 10px;

    .no-products {
        text-align: center;
    }

    .ProductListItem {
        width: 33.33%;
        float: left;
        padding: 10px;

        h1 {
            text-align: center;
        }

        @include img-hover;
    }
}

@media (max-width: 550px) {
    .ProductList .ProductListItem {
        width: 50%;
    }
}

@media (max-width: 350px) {
    .ProductList .ProductListItem {
        width: 100%;
    }
}
