.CheckOutContainer {

    .required {
        color: #bb1212;
        padding-left: 5px;
        font-size: 22px;
    }

    .valid {
        color: green;
        font-size: 18px;
    }

    .Customer,
    .Payment,
    .Shipping {
        width: 50%;
        float: left;
        padding: 0 20px 20px;
    }

    .Customer,
    .Shipping {

        .textbox {
            margin: 5px 0;

            label {
                width: 105px;
                display: inline-block;
            }

            input {
                width: 65%;
                height: 30px;
            }

            .error {
                width: 70%;
                margin: 0 30px 0 95px;
            }
        }

        #remember-me {
            margin-left: 97px;
        }
    }

    .Payment {
        h1 span {
            font-size: inherit;
        }

        .CreditCards label {
            display: block;
            background-color: #eee;
            padding: 5px;
            margin: 5px 0;
            cursor: pointer;

            input {
                vertical-align: sub;
            }

            img {
                width: 30px;
                height: 20px;
                margin: 0 10px;
                vertical-align: middle;
            }

            span {
                float: right;
            }
        }

        .AcceptConditions {
            padding: 15px 0;
            display: block;

            input[type=checkbox] {
                width: 9%;
                float: left;
                margin: 0;
                margin-top: 2.2px;
            }

            span {
                width: 91%;
                float: left;
            }
        }
    }

    .Customer .AcceptConditions {
        margin: 5px 0 0 97px;
    }

    .DibsForm {
        width: 100%;
        margin: auto;

        #dibsiframe {
            width: 100%;
        }
    }
}

@media (max-width: 810px) {
    .CheckOutContainer {
        .Customer,
        .Shipping {
            .textbox input {
                width: 60%;
            }
        }
    }
}

@media (max-width: 720px) {
    .CheckOutContainer {
        .Customer,
        .Shipping {
            .textbox input {
                width: 55%;
            }
        }

        .Shipping .textbox label {
            width: 75px;
        }
    }
}

@media (max-width: 650px) {
    .CheckOutContainer {
        .Customer,
        .Shipping,
        .Payment {
            width: 100%;
        }

        .Customer .textbox input {
            width: 55%;
        }

        input[type=text],
        input[type=tel],
        input[type=email],
        input[type=password] {
            font-size: 16px;
        }

        .Shipping .textbox label {
            width: 95px;
        }

        .Payment label {
            width: 75%;
        }
    }
}

@media (max-width: 450px) {
    .CheckOutContainer {
        .Payment label {
            width: 100%;
        }
    }
}

@media (max-width: 300px) {
    .CheckOutContainer {
        label {
            font-size: 13px;
        }

        .Customer,
        .Shipping {
            .textbox {
                label {
                    width: 100%;                   
                }

                input {
                    width: 80%;
                }
            }
        }
    }
}
