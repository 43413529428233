﻿@mixin user-select($select) {
    -webkit-user-select: $select; /* Chrome all / Safari all */
    -moz-user-select: $select; /* Firefox all */
    -ms-user-select: $select; /* IE 10+ */
    user-select: $select;
}

@mixin img-hover() {
    &:hover > img {
        cursor: pointer;
        transform: scale(1.04);
        box-shadow: inset 0 0 1em black, 0 0 1em black;
    }
}

@mixin red {
    .red {
        background-color: darkred;
        color: white;

        &:hover {
            background-color: #9d0000;
        }
    }
}

@mixin green {
    .green {
        background-color: green;
        color: white;

        &:hover {
            background-color: forestgreen;
        }
    }
}
