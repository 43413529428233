.Receipt {
    padding: 10px 20px 20px;

    hr {
        margin: 40px 0 20px;
        border-color: #666;

        &:first-of-type {
            margin-top: 20px;
        }
    }

    .gray {
        min-width: 120px;
        height: 35px;
        background-color: buttonface;
        margin-right: 5px;

        &:disabled {
            pointer-events: none;
            opacity: 0.7;
            transition: opacity, ease-in .5s;
        }
    }

    .gray.back {
        background-color: gray;
        color: white;
        float: right;
    }

    .Summary:first-of-type {
        position: static;
        background: none;

        h3 {
            text-align: left;
            width: 100%;
            padding-left: 0;
            float: none;
            font-size: 18px;
            margin: 0 0 5px;
        }

        .summary-info {
            padding: 0 0 20px;
            background: inherit;
            color: inherit;

            div {
                width: 100%;
                text-align: left;
            }
        }
    }

    .Voucher {
        width: 100%;
        float: left;
        margin-bottom: 20px;
        color: black;
        position: relative;

        .background {
            position: relative;
        }

        .info {
            width: 50%;
            margin: auto;
            padding: 0 20px;
            position: absolute;
            overflow: hidden;
            top: 20%;
            left: 26%;
            height: 78%;
            font-size: 15px;

            .price {
                font-size: 35px;
                font-weight: 700;
            }
        }

        .qr-code {
            width: 16%;
            position: absolute;
            top: 32%;
            right: 3.8%;
            text-align: center;
            font-size: 12px;
        }
    }

    .copyright {
        color: #979797;
    }
}

@media (max-width: 1024px) {
    .Receipt .Voucher {

        .qr-code {
            font-size: 1.2vw;
        }

        .info {
            font-size: 1.4vw;

            .price {
                font-size: 3.5vw;
            }
        }
    }
}

@media (max-width: 415px) {
    .Receipt {
        .back {
            margin: auto;
            margin-top: 50px;
        }
    }
}
