@import '../../../styles/mixins';

.ProductContainer {
    h4 {
        margin: 0;
    }

    .img-quantity,
    .product-info {
        float: left;
    }

    .img-quantity {
        width: 40%;
        padding: 20px 10px 20px 20px;
        text-align: center;

        img {
            width: auto;
            max-width: 100%;
        }

        input {
            margin-left: 0;
        }

        .quantity-selectors {
            display: table;
            width: 100%;
        }
    }

    .product-info {
        width: 60%;
        padding: 30px 20px 0 10px;
    }

    .Message {
        width: 60%;
        float: right;
        padding: 0 20px 20px 10px;
    }

    .quantity-container {
        width: 50%;
        display: table-cell;

        &.disabled {
            color: gray;
            pointer-events: none;
        }

        h4 {
            display: block;
            margin: 10px 0;
            vertical-align: sub;
            cursor: pointer;
        }
    }

    .price, .Amount {
        display: inline-block;
        float: right;
        font-size: 26px;
    }

    .Amount {
        width: 100%;

        input {
            width: 75px;
            font-size: 28px;
            text-align: center;
            padding: 2px;
            margin-top: 4px;
        }
    }

    .Combobox .quantity-container {
        float: left;
        display: flex;
        flex-direction: column;
    }

    .QuantitySelector {
        @include user-select(none);
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .increment,
        .decrement {
            width: 35px;
            height: 35px;
            font-size: 35px;
            vertical-align: text-bottom;
            display: inline-flex;
            cursor: pointer;
            border-radius: 50%;

            &:hover {
                background-color: lightgray;
            }
        }

        .deactivated {
            color: gray;
            cursor: default;
            pointer-events: none;

            &:hover {
                background-color: transparent;
            }
        }

        .quantity {
            display: inline;
            vertical-align: sub;
            margin: 0 10px 0 12px;
            font-size: 35px;
        }
    }

    textarea {
        width: 100%;
        max-width: 100%;
        min-height: 150px;
    }
}

@media (max-width: 820px) {
    .ProductContainer {
        .quantity-container {
            font-size: 14px;
        }

        .QuantitySelector {
            .increment,
            .decrement {
                width: 25px;
            }

            .quantity {
                font-size: 30px;
            }
        }
    }
}

@media (max-width: 650px) {
    .ProductContainer {
        .img-quantity {
            width: 50%;
        }

        .Message {
            width: 100%;
            padding-left: 20px;

            textarea {
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 530px) {
    .ProductContainer {
        .img-quantity,
        .product-info {
            width: 100%;
        }

        .product-info {
            padding: 0 20px;
        }

        .img-quantity {
            padding: 20px 0 0 20px;

            .QuantitySelector,
            .Amount {
                width: 100%;
            }
        }

        img,
        .quantity-container {
            float: left;
        }

        img {
            width: 58%
        }

        .quantity-container {
            width: 100%;
            padding: 10px 0 15px;

            label {
                margin: 0;
            }
        }
    }
}

@media (max-width: 360px) {
    .ProductContainer {
        .img-quantity {
            padding: 20px 20px 10px;
            text-align: center;

            img {
                width: 100%;
            }
        }

        .quantity-container {
            width: 50%;
            padding: 0;

            label {
                margin-top: 11px;
            }

            &.digital {
                width: 100%;
            }
        }

        h1 {
            margin-top: 10px;
        }
    }
}


@media (max-width: 265px) {
    .ProductContainer .Product {
        .quantity-container {
            width: 100%;
        }
    }
}
