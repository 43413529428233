@mixin transform($props) {
    -webkit-transform: $props;
    -moz-transform: $props;
    -o-transform: $props;
    transform: $props;
}

@mixin transition($props) {
    -webkit-transition: $props;
    -moz-transition: $props;
    -o-transition: $props;
    transition: $props;
}

nav {
    position: fixed;
    z-index: 1100;
    width: 100%;
    background-color: #2e2e2e;
    height: 75px;

    #burger-menu {
        display: none;
    }

    .burger-menu-logo {
        display: none;
    }

    ul {
        max-width: 1170px;
        margin: auto;
        padding: 0;
        position: relative;

        .logo img {
            width: auto;
            max-height: 60px;
        }

        li {
            display: inline-block;
            font-size: 18px;
            position: relative;
            z-index: 1000;
            vertical-align: middle;

            &:hover {
                color: gray;
                cursor: pointer;
            }

            .nested-menu {
                display: none;
                position: absolute;
                top: 100%;
                background-color: #2e2e2e;

                li {
                    display: block;
                }
            }

            &:hover > .nested-menu {
                display: block;
            }

            a {
                display: inline-block;
                padding: 10px 20px;
                color: white;
                text-decoration: none;
                transition: all .2s ease;

                &.active {
                    color: lightgray;
                    text-decoration: underline;
                }

                &:hover {
                    color: lightgray;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    nav {
        height: 40px;

        ul {
            width: 100%;
            position: absolute;
            left: 0;
            z-index: 1000;
            margin: 0;
            background-color: #2e2e2e;

            @include transition(left .25s ease-in-out);

            li, a {
                font-size: 18px;
                width: 100%;
            }

            a img {
                width: auto;
            }

            li .nested-menu {
                display: block;
                position: relative;
                padding-left: 20px;
            }

            &.burger-menu-closed {
                left: -100%;
            }
        }

        .burger-menu-logo {
            width: 150px;
            height: 30px;
            display: inline-block;
            text-align: center;
        }

        #burger-menu {
            display: block;
            width: 26px;
            height: 15px;
            display: inline-block;
            margin: 10px;
            position: relative;
            @include transform(rotate(0deg));
            @include transition(.5s ease-in-out);
            cursor: pointer;
        }

        #burger-menu span {
            display: block;
            position: absolute;
            height: 4px;
            width: 100%;
            background: white;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            @include transform(rotate(0deg));
            @include transition(.25s ease-in-out);
        }

        #burger-menu span:nth-child(1) {
            top: 0px;
        }

        #burger-menu span:nth-child(2), #burger-menu span:nth-child(3) {
            top: 8px;
        }

        #burger-menu span:nth-child(4) {
            top: 16px;
        }

        #burger-menu.open span:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        #burger-menu.open span:nth-child(2) {
            @include transform(rotate(45deg));
        }

        #burger-menu.open span:nth-child(3) {
            @include transform(rotate(-45deg));
        }

        #burger-menu.open span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
}
